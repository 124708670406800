/* eslint-disable no-irregular-whitespace */
import React from 'react';
import { NextSeo } from 'next-seo';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
// import { SwiperSlide, Swiper } from 'swiper/react';
import { GetServerSideProps } from 'next';
// import Axios from 'axios';
import RowDataPacket from 'mysql2/promise';
// import FadeInSection from '../components/FadeInSection';
// import SectionHeader from '../components/SectionHeader';
import { TCMS } from '../types/cms';
import { renderContent } from '../utils/cms';
import db from '../utils/db';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

type TProps = {
    content: TCMS[];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getServerSideProps: GetServerSideProps = async (): Promise<any> => {
    try {
        // if (process.env.NODE_ENV !== 'production') {
        //     Axios.defaults.baseURL = 'http://127.0.0.1:3000';
        // }
        // const { data } = await Axios.post<TCMS[]>('/api/page_get', { slug: '/_homepage' }, {});

        // return {
        //     props: {
        //         content: data,
        //     },
        // };
        const [rows] = await db.query<RowDataPacket.RowDataPacket[]>(
            `
                    SELECT detail.*
        FROM page detail
        INNER JOIN
            (SELECT page.slug, MAX(createdAt) AS maxCreatedBy
            FROM page
            GROUP BY slug) groupeddetail
        ON detail.slug = groupeddetail.slug
        AND detail.createdAt like groupeddetail.maxCreatedBy
        WHERE published like 1 AND detail.slug LIKE ?
            `,
            ['/_homepage'],
        );
        if (rows) {
            const data = JSON.parse(rows[0].content);
            if (rows.length) {
                return {
                    props: {
                        content: data,
                    },
                };
            }
            return {
                props: {
                    content: [],
                },
            };
        }
        return {
            props: {
                content: [],
            },
        };
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
    }

    return {
        props: {
            error: 'Not found',
        },
    };
};

// const gallery = [
//     {
//         src: '1',
//         title: 'At the HKISO training workshop: Primary Students',
//     },
//     {
//         src: '2',
//         title: 'At the HKISO training workshop: Science undergraduates guided the students',
//     },
//     {
//         src: '3',
//         title:
//             'At the HKISO training workshop: Dr. Chanakya had a few words for the parents at the conclusion (the competition is to be held on 21 Dec)',
//     },
//     {
//         src: '4',
//         title: 'At the TIMO Training Workshop',
//     },
//     {
//         src: '5',
//         title: 'At the TIMO Training Workshop: the heat round was conducted on 30 Nov',
//     },
//     {
//         src: '6',
//         title: 'At the TIMO training workshop',
//     },
// ];

// const news = [
//     {
//         title: 'VANDA INTERNATIONAL SCIENCE COMPETITION - REVIEW/VANDA SCIENCE GLOBAL - FINAL PREPARATION',
//         date: 'December 23rd, 2020',
//         content: 'VANDA INTERNATIONAL SCIENCE COMPETITION - REVIEW/VANDA SCIENCE GLOBAL - FINAL PREPARATION',
//         url: '/news/vanda-002',
//     },
//     {
//         title: 'INTERNATIONAL JUNIOR MATH OLYMPIAD & VANDA SCIENCE GLOBAL FINALS ( 5 DECEMBER, ONLINE )',
//         date: 'December 23rd, 2020',
//         content: 'INTERNATIONAL JUNIOR MATH OLYMPIAD & VANDA SCIENCE GLOBAL FINALS ( 5 DECEMBER, ONLINE )',
//         url: '/news/vanda-001',
//     },
//     {
//         title: 'Thailand International Mathematical Olympiad (TIMO 2020-2021) Heat Round – Online',
//         date: 'December 23rd, 2020',
//         content: 'Thailand International Mathematical Olympiad (TIMO 2020-2021) Heat Round – Online',
//         url: '/news/timo-001',
//     },
//     {
//         title: 'Live Webinar: STEAM AHEAD 2020',
//         date: 'November 18th, 2020',
//         content: 'Live Webinar: STEAM AHEAD 2020',
//         url: '/news/webinar-001',
//     },
//     {
//         title: 'SLMC 2019 CERTIFICATES – CORRECTIONS',
//         date: 'November 12th, 2020',
//         content: 'IMPORTANT - SLMC 2019 CERTIFICATES Certificates for the above awards',
//         url: '/news/slmc-2019-certificate-corrections',
//     },
//     {
//         title: 'IN RETROSPECT',
//         date: 'November 11th, 2020',
//         content: 'IN RETROSPECT ..............." MATH TALK ___#3 ”Mathematics EDS Meet a ',
//         url: '/news/retrospect',
//     },
//     {
//         title: 'MATH TALK #3',
//         date: 'November 11th, 2020',
//         content: '" MATH TALK ___ #3',
//         url: '/news/math-talk-3',
//     },
//     {
//         title: 'PANDEMIC COVID 19',
//         date: 'November 11th, 2020',
//         content: 'STAY SAFE !!! COVID-19 pandemic due to spread of SARS-CoV-2 ',
//         url: '/news/pandemic-covid',
//     },
//     {
//         title: 'MATH TALK #2',
//         date: 'November 11th, 2020',
//         content: '" MATH TALK ___ #2 Biography:Dr. Udita Katugampola is an',
//         url: '/news/math-talk-2',
//     },
//     {
//         title: 'Global Quarantine Mathematical Olympiad',
//         date: 'November 11th, 2020',
//         content: 'Global Quarantine Mathematical Olympiad Bored at home with nothing to',
//         url: '/news/quarantine-olympiad',
//     },
//     {
//         title: 'MATH TALK # 1',
//         date: 'November 11th, 2020',
//         content: '" " MATH TALK___ #1 The two hour Zoom meeting took',
//         url: '/news/math-talk-1',
//     },
//     {
//         title: 'CA Sri Lanka and Sri Lanka Olympiad Mathematics Foundation kicks off ‘Chanakya’s Mind Games’ …',
//         date: 'March 3rd, 2020',
//         content: 'CA Sri Lanka and Sri Lanka Olympiad Mathematics Foundation kicks',
//         url: '/news/mind_games',
//     },
//     {
//         title: 'World International Mathematical Olympiad (WIMO) 2019 [27 – 30 DEC Tokyo – Japan]',
//         date: 'March 3rd, 2020',
//         content: 'WIMO 2019 (27-30 DEC, TOKYO JAPAN) :- Sri Lanka team',
//         url: '/news/wimo',
//     },
//     {
//         title: 'MESSAGE TO TIMO 2019/2020 HEAT ROUND CONTESTANTS:-',
//         date: 'March 3rd, 2020',
//         content: 'Thailand International Mathematical Olympiad (TIMO) MESSAGE TO TIMO 2019/2020 HEAT',
//         url: '/news/timo',
//     },
//     {
//         title: 'TIMO & HKIMO Heats: Award Ceremony – 2019*',
//         date: 'March 3rd, 2020',
//         content: 'TIMO & HKIMO Heats: Award Ceremony - 2019 The Award',
//         url: '/news/hkimo',
//     },
//     {
//         title: 'Hong Kong International Science Olympiad (HKISO)',
//         date: 'March 3rd, 2020',
//         content: 'World International Mathematical Olympiad (WIMO) (For Students from Grades 1',
//         url: '/news/hkiso',
//     },
//     {
//         title: 'International Teenagers’ Mathematics Olympiad (ITMO) 2019',
//         date: 'March 3rd, 2020',
//         content: `International Teenagers' Mathematics Olympiad (ITMO) 2019 Sri Lanka team to`,
//         url: '/news/itmo',
//     },
//     {
//         title: 'IMO 2019',
//         date: 'March 3rd, 2020',
//         content: 'International Mathematical Olympiad (IMO) Maneth Perera won a Bronze medal',
//         url: '/news/imo',
//     },
//     {
//         title: 'IMC Selection Test 2019',
//         date: 'March 3rd, 2020',
//         content: 'International Mathematics Competition (IMC) 2019 IMC Selection Test 2019 IMC ',
//         url: '/news/imc',
//     },
//     {
//         title: 'An Exciting Problem…',
//         date: 'March 3rd, 2020',
//         content: 'An Exciting Problem to Strectch Your Mind... The Solution..........',
//         url: '/news/exciting-problem',
//     },
//     {
//         title: 'Challenging Mathematics – Problem of the Week Competition',
//         date: 'March 3rd, 2020',
//         content: 'Challenging Mathematics - Problem of the Week Competition Problem A',
//         url: '/weekly_quiz/challenging_maths/about',
//     },
//     {
//         title: 'TIMO & HKISO HEAT ROUNDS',
//         date: 'November 9th, 2019',
//         content: 'DetailsDownload',
//         url: '/pdf/news/Web-notice2.pdf',
//     },
//     {
//         title: 'Thailand International Mathematical Olympiad (TIMO) FINAL 2020  ',
//         date: 'November 9th, 2019',
//         content: 'TIMO FINAL 2020 BROCHURE-2-TIMO-Final-2020-2Download',
//         url: '/pdf/news/BROCHURE-2-TIMO-Final-2020-2.pdf',
//     },
//     {
//         title: 'President Meets Sri Lanka Team to International Mathematics Olympiad 2019',
//         date: 'July 29th, 2019',
//         content: 'IMO 2019 President Meets Sri Lanka Team to International Mathematics ',
//         url: '/news/imo-team',
//     },
//     {
//         title: 'Sri Lanka Team to IMO 2019',
//         date: 'July 29th, 2019',
//         content: 'Sri Lanka Team to IMO 2019 60th International Mathematical Olympiad this',
//         url: '/news/imo-2019',
//     },
//     {
//         title: 'සතියේ පැනය﻿',
//         date: 'January 14th, 2019',
//         content: 'සතියේ පැනය  -සෑම සතියකම අභියෝගාත්මක ගණිතමය ගැටලුවක් MCQ ගැටලුවක් ලෙස',
//         url: '/news/weekly-quiz-s',
//     },
//     {
//         title: 'Problem of the week',
//         date: 'January 14th, 2019',
//         content: 'Problem of the week -Every week a challenging mathematical problem',
//         url: '/news/weekly-quiz-e',
//     },
//     {
//         title: 'ශ්‍රී ලංකාවේ IMO සිහිනය',
//         date: 'September 12th, 2018',
//         content: '',
//         url: '/news/imo-sl',
//     },
//     {
//         title: 'Vidusara Article',
//         date: 'September 12th, 2018',
//         content: 'Please click here to read the article.',
//         url: '/weekly_quiz/mind_games/about',
//     },
// ];

const Index = ({ content }: TProps): JSX.Element => {
    return (
        <div>
            <NextSeo
                title="Sri Lanka Olympiad Mathematics Foundation"
                description="Sri Lanka Olympiad Mathematics Foundation is a non-profit organization composed of volunteers and dedicated to popularize mathematics at the school level."
                canonical="https://www.slmathsolympiad.org"
                openGraph={{
                    url: 'https://www.slmathsolympiad.org',
                    title: 'SLOMF - Sri Lanka Olympiad Mathematics Foundation',
                    description:
                        'Sri Lanka Olympiad Mathematics Foundation (SLOMF) is a non-profit organization composed of volunteers and dedicated to popularize mathematics at the school level.',
                    images: [
                        {
                            url: '',
                            alt: '',
                        },
                        {
                            url: '',
                            alt: '',
                        },
                    ],
                }}
            />

            {renderContent(content)}
        </div>
    );
};

export default Index;
